import { Stack, Typography } from '@mui/material'

import { scrollToTop } from '../../utils/scroll'

import { logoChevronSx, logoSx, logoWrapperSx } from './styles'

const Logo = () => {
  const handleOnLogoClick = () => {
    scrollToTop()
  }

  return (
    <Stack direction="row" sx={logoWrapperSx}>
      <Typography sx={logoChevronSx}>{`<`}</Typography>
      <Typography sx={logoSx} onClick={handleOnLogoClick}>
        Jacob Kapostins
      </Typography>
      <Typography sx={logoChevronSx}>{`>`}</Typography>
    </Stack>
  )
}

export default Logo
