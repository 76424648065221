import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  display: 'flex',
  flex: 1,
  flexDirection: { xs: 'column', lg: 'row' },
  padding: { xs: '1.6rem', lg: 0 },
  alignItems: { xs: 'center', lg: 'flex-start' },
  justifyContent: { xs: 'center', lg: 'flex-start' },
}

export const navSx: SxProps<Theme> = {
  alignItems: 'center',
  flexDirection: { xs: 'column', lg: 'row' },
  flex: { xs: 0, lg: 1 },
}

export const downloadButtonSx: SxProps<Theme> = {
  marginLeft: { xs: 0, lg: '2.4rem' },
  marginTop: { xs: '2.4rem', lg: 0 },
  width: { xs: 'fit-content', lg: 'auto' },
}
