import { Button as MuiButton, ButtonProps, SxProps, Theme } from '@mui/material'

import { buttonSx } from './styles'

const Button = ({ children, sx, variant, ...rest }: ButtonProps) => {
  return (
    <MuiButton
      sx={{ ...buttonSx({ variant }), ...sx } as SxProps<Theme>}
      variant={variant}
      {...rest}
    >
      {children}
    </MuiButton>
  )
}

export default Button
