import { SxProps, Theme } from '@mui/material'

export const modalSx: SxProps<Theme> = {
  position: 'sticky',
  top: '50%',
  outline: 'none',
}

export const wrapperSx: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
}

export const innerWrapperSx: SxProps<Theme> = {
  position: 'relative',
  outline: 'none',
}

export const closeIconWrapperSx: SxProps<Theme> = {
  position: 'absolute',
  top: '-3.2rem',
  right: '-1.8rem',
  cursor: 'pointer',
}

export const imageSx: SxProps<Theme> = {
  maxHeight: '85vh',
  maxWidth: '85vw',
  outline: 'none',
}
