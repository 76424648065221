import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  position: 'relative',
  marginLeft: { xs: '1.6rem', md: '3rem' },
}

export const contentSx: SxProps<Theme> = {
  position: 'relative',
  background: 'rgba(30, 40, 60, 0.85)',
  backdropFilter: 'blur(3px)',
  padding: { xs: '2rem', md: '3.2rem' },
  gap: '5.6rem',
  flexDirection: { xs: 'column', lg: 'row' },
}

export const copyContentSx: SxProps<Theme> = {
  gap: { xs: '1.2rem', md: '2.4rem' },
}

export const subtitlesWrapperSx: SxProps<Theme> = {
  gap: '0.4rem',
}

export const borderSx = (variant: 'left' | 'right'): SxProps<Theme> => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: { xs: '1.6rem', md: '3rem' },
  border: '2px solid',
  borderImage: (theme) =>
    `linear-gradient(135deg, ${theme.palette.secondary.dark}, ${theme.palette.primary.main}) 1`,
  borderImageSlice: 1,
  zIndex: -1,

  ...(variant === 'left' && {
    left: { xs: 'auto', md: 'auto' },
    right: { xs: '1.6rem', md: '3rem' },
  }),

  ...(variant === 'right' && {
    left: '3rem',
    right: 'auto',
  }),
})

export const imageWrapperSx: SxProps<Theme> = {
  position: 'relative',
  height: { xs: '25.2rem', md: '69.6rem', lg: 'auto' },
}

export const imageSx = ({
  featuredImage,
}: {
  featuredImage: string
}): SxProps<Theme> => ({
  background: `url(${featuredImage})`,
  backgroundPosition: 'top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  cursor: 'pointer',
  height: '100%',
  width: { xs: '100%', lg: '50.4rem' },
  filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25))',
})

export const subtitleWrapperSx: SxProps<Theme> = {
  gap: '0.8rem',
  alignItems: 'baseline',
}

export const subtitleSx: SxProps<Theme> = {
  fontWeight: 700,
  color: (theme) => theme.palette.secondary.main,
}

export const chipSx: SxProps<Theme> = {
  maxWidth: 'fit-content',
}

export const overlaySx: SxProps<Theme> = {
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: (theme) =>
    `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '0.8rem',
  transition: 'opacity 0.3s ease-in-out',
  opacity: 0,

  '&:hover': {
    opacity: 1,
  },
}
