import { SxProps, Theme } from '@mui/material'

export const heroSx: SxProps<Theme> = {
  display: 'flex',
  marginTop: { xs: '6.2rem', md: '7.5rem', lg: '14rem' },
  justifyContent: 'space-between',
  flexDirection: { xs: 'column', md: 'row' },
}

export const heroCopySx: SxProps<Theme> = {
  rowGap: '2.4rem',
  marginBottom: { xs: '2.4rem', md: 0 },
}

export const heroTitleSx: SxProps<Theme> = {
  maxWidth: '60rem',
}

export const heroDescriptionCopySx: SxProps<Theme> = {
  marginTop: { xs: '5rem', md: 0 },
  gap: { xs: '1.6rem', md: '2.4rem' },
}

export const heroSubtitleSx: SxProps<Theme> = {
  color: (theme) => theme.palette.primary.contrastText,
  maxWidth: { lg: '52rem' },
  fontSize: { xs: '1.6rem', lg: '2.6rem' },
  lineHeight: { xs: '2.8rem', lg: '4.6rem' },
}

export const heroButtonSx: SxProps<Theme> = {
  alignSelf: 'flex-start',
  width: { xs: '100%', md: 'auto' },
  fontSize: { xs: '2.2rem', lg: '1.5rem' },
}

export const heroPictureSx: SxProps<Theme> = {
  position: 'relative',
  flexGrow: 1,
  marginRight: '2.4rem',
}

export const heroWrapperSx: SxProps<Theme> = {
  position: 'relative',
  width: { xs: '100%', md: '35.5rem', lg: '57.3rem' },
  maxWidth: { xs: '31.5rem', md: '35.5rem', lg: '57.3rem' },
  height: { xs: '31.5rem', md: '35.5rem', lg: '57.3rem' },
  alignSelf: { xs: 'flex-start', md: 'flex-end' },
}

const sharedImageProps: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: { xs: '100%', md: '35.5rem', lg: '57.3rem' },
  maxWidth: { xs: '31.5rem', md: '35.5rem', lg: '57.3rem' },
  height: { xs: '31.5rem', md: '35.5rem', lg: '57.3rem' },
}

export const heroImageSx: SxProps<Theme> = {
  ...sharedImageProps,
  zIndex: 2,
}

export const heroBlendImageSx: SxProps<Theme> = {
  ...sharedImageProps,
  mixBlendMode: 'overlay',
  opacity: 0.7,
  zIndex: 3,
}

export const heroImageBorderSx: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: { xs: '2.4rem', lg: '1.6rem' },
  left: { xs: '2.4rem', lg: '1.6rem' },
  border: '2px solid',
  borderImage: (theme) =>
    `linear-gradient(135deg, ${theme.palette.secondary.dark}, ${theme.palette.primary.main}) 1`,
  borderImageSlice: 1,
}
