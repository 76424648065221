export const download = ({
  buffer,
  contentType,
  filename,
}: {
  buffer: ArrayBuffer
  contentType: string
  filename: string
}) => {
  const file = new Blob([buffer], { type: contentType })
  const fileUrl = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileUrl
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

const exports = {
  download,
}

export default exports
