import { SxProps, Theme } from '@mui/material'

export const backgroundSx: SxProps<Theme> = {
  position: 'relative',
  bottom: '-7rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: (theme) => ({
    xs: theme.palette.primary.dark,
    lg: `linear-gradient(90deg, ${theme.palette.primary.dark} 50%, ${theme.palette.background.default} 50%)`,
  }),
  width: '100%',
  marginTop: { xs: '2.4rem', lg: '7.3rem' },
  minHeight: { lg: '66.1rem' },
}

export const wrapperSx: SxProps<Theme> = {
  gap: '4rem',
  maxWidth: '156.7rem',
  padding: { lg: '0 4.5rem' },
}

export const leftWrapperSx: SxProps<Theme> = {
  maxWidth: { lg: 'calc(50% - 4rem)' },
  rowGap: '2rem',
  justifyContent: 'center',
  padding: { xs: '4rem', lg: 0 },
}

export const descriptionTextSx: SxProps<Theme> = {
  fontWeight: 400,
  fontSize: '2.2rem',
  lineHeight: '4.2rem',
}

export const downloadResumeButtonSx: SxProps<Theme> = {
  alignSelf: 'flex-start',
}

export const formWrapperSx: SxProps<Theme> = {
  position: 'relative',
  padding: '4rem',
  width: '100%',
  background: (theme) => theme.palette.background.default,
}
