import { useRef } from 'react'

import { Box, Dialog, IconButton, useTheme } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faClose } from '@fortawesome/free-solid-svg-icons'

import ExpandedMenu from '../ExpandedMenu'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import {
  closeIconWrapperSx,
  dialogSx,
  iconButtonStyles,
  logoWrapperSx,
  menuPaperSx,
} from './styles'
import Logo from '../../../Logo'
import { useMenu } from '../../../../hooks/useMenu'

interface ICollapsedMenuProps {
  activeSection: HOME_ANCHOR_ID
}

const CollapsedMenu = ({ activeSection }: ICollapsedMenuProps) => {
  const theme = useTheme()
  const { isMenuOpen, onMenuClick, onMenuClose } = useMenu()

  const menuButonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <IconButton
        id="menu-button"
        aria-label="Fullscreen menu button"
        ref={menuButonRef}
        aria-haspopup="true"
        aria-controls={isMenuOpen ? 'menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={onMenuClick}
      >
        <FontAwesomeIcon icon={faBars} style={iconButtonStyles(theme)} />
      </IconButton>
      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={onMenuClose}
        sx={dialogSx}
        PaperProps={{ sx: menuPaperSx }}
      >
        <Box>
          <Box sx={closeIconWrapperSx} onClick={onMenuClose}>
            <FontAwesomeIcon icon={faClose} size="lg" />
          </Box>
        </Box>
        <Box sx={logoWrapperSx}>
          <Logo />
        </Box>
        <ExpandedMenu
          activeSection={activeSection}
          onNavigationItemClicked={onMenuClose}
        />
      </Dialog>
    </>
  )
}

export default CollapsedMenu
