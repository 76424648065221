import { SxProps, Theme } from '@mui/material'

export const logoWrapperSx: SxProps<Theme> = {
  cursor: 'pointer',
}

export const logoChevronSx: SxProps<Theme> = {
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: { xs: '2.637rem', lg: '3.2rem' },
  lineHeight: { xs: '3.591rem', lg: '3.2rem' },
  color: (theme) => theme.palette.primary.contrastText,
}

export const logoSx: SxProps<Theme> = {
  background: (theme) =>
    `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  fontWeight: 700,
  fontSize: { xs: '2.637rem', lg: '3.2rem' },
  lineHeight: { xs: '3.591rem', lg: '3.2rem' },
}
