import { Box, Stack, Typography } from '@mui/material'

import { useResponsive } from '../../hooks/useResponsive'

import { borderSx, wrapperSx, contentSx, featuredCopySx } from './styles'

const WhatIsThis = () => {
  const { isMobile } = useResponsive()

  return (
    <Stack>
      <Typography variant="h1">What is this?</Typography>
      <Box sx={wrapperSx}>
        <Stack sx={contentSx}>
          <Typography variant="body2" sx={featuredCopySx}>
            This is a portfolio that showcases my diverse range of projects,
            highlighting my technical expertise, problem-solving skills, and
            ability to lead and collaborate with teams. From graphics
            programming for game consoles to full-stack development and
            technical leadership roles, each project showcases my capabilities.
          </Typography>
          <Typography variant={isMobile ? 'subtitle1' : 'body1'}>
            I have extensive experience in graphics programming for Sony
            PlayStation and Microsoft Xbox game consoles and a proven track
            record of delivering high-quality results. More recently, I have
            broadened my skillset to include full-stack development, with a
            focus on AWS, and have taken on technical leadership roles,
            including co-founding “The Launchpad” and serving as its CTO. As a
            technology leader, I have a successful record of leading projects
            and guiding teams to achieve their goals. My lifelong commitment to
            learning and staying up-to-date with the latest technologies allows
            me to handle complex projects and find innovative solutions to
            challenges.
          </Typography>
          <Typography>
            If you have any questions or just want to talk tech, don't hesitate
            to reach out via the form at the bottom of this page.
          </Typography>
        </Stack>
        <Box sx={borderSx} />
      </Box>
    </Stack>
  )
}

export default WhatIsThis
