import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  marginTop: '5.6rem',
  gap: '4rem',
}

export const itemsWrapperSx: SxProps<Theme> = {
  gap: '8.4rem',
}
