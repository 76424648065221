export const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, 0)
}

export const scrollToElement = (
  event: React.MouseEvent,
  id: string
) => {
  event.preventDefault()

  const element = document.getElementById(id)

  if (element) {
    const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -120
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }
}