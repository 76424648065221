import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/noto-sans'

import { FC, PropsWithChildren } from 'react'

import { Breakpoint, CssBaseline } from '@mui/material'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles'

export const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 390,
  md: 834,
  lg: 1440,
  xl: 1920,
}

const theme = createTheme({
  typography: {
    fontFamily: '"Open Sans", Arial, Roboto, source-code-pro',
    htmlFontSize: 10,
    allVariants: {
      color: '#FFFFFF',
    },
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: '9rem',
      lineHeight: '10.4rem',
      fontWeight: 700,
      background: 'linear-gradient(135deg, #A8F2EC 0%, #A17DED 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: '5.5rem',
        lineHeight: '6.5rem',
      },
    },
    h3: {
      fontWeight: 700,
      fontSize: '4rem',
      lineHeight: '5.4rem',
      color: '#CAB0FF',
    },
    h4: {
      fontWeight: 600,
      fontSize: '3.2rem',
      lineHeight: '3.2rem',
    },
    h5: {
      fontWeight: 400,
      fontSize: '2.6rem',
      lineHeight: '4.6rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '2.4rem',
      lineHeight: '3.8rem',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '1.6rem',
      lineHeight: '3.1rem',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '1.4rem',
      lineHeight: '3.2rem',
    },
    body1: {
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: '3.4rem',
    },
    body2: {
      fontWeight: 700,
      fontSize: '2.4rem',
      lineHeight: '3.4rem',
      color: '#CAB0FF',
    },
  },
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    primary: {
      main: '#A8F2EC',
      dark: '#1E283C',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#CAB0FF',
      dark: '#A17DED',
    },
    background: {
      default: '#7337F2',
    },
    grey: {
      300: '#828282',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
        outlinedSizeMedium: {
          padding: '0.8rem 2rem',
        },
        outlinedSizeLarge: {
          padding: '1.6rem 2.4rem',
        },
        containedSizeMedium: {
          padding: '0.8rem 2rem',
        },
        containedSizeLarge: {
          padding: '1.6rem 2.4rem',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Noto Sans',
          fontStyle: 'normal',
          fontWeight: 600,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          borderRadius: '2rem',
          border: 'double 1px transparent',
          background:
            'linear-gradient(#1A2436, #1A2436), radial-gradient(circle at top left, #A8F2EC, #A17DED)',
          backgroundOrigin: 'border-box',
          backgroundClip: 'content-box, border-box',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#0A0D13',
          '.MuiInputBase-root': {
            borderRadius: 0,
            background: '#FFFFFF',
          },
          '.MuiInputLabel-root': {
            color: '#0A0D13',
            fontWeight: 400,
            fontSize: '1.8rem',
            lineHeight: '2.5rem',
          },
          '.MuiInputBase-input': {
            fontWeight: 400,
            '::placeholder': {
              color: '#0A0D13',
              fontWeight: 400,
              fontSize: '1.8rem',
              lineHeight: '2.5rem',
              opacity: 1,
            },
          },
          fieldset: {
            border: 'none',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: `linear-gradient(rgba(10, 13, 19, 0.9), rgba(10, 13, 19, 0.9)), url(poligons.png)`,
          backgroundColor: '#0A0D13',
          backdropFilter: 'blur(4px)',
          backgroundAttachment: 'fixed',
        },
      },
    },
  },
})

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

export { theme }
export default ThemeProvider
