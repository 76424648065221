import { SxProps, Theme } from '@mui/material'

export const navigationItemSx = ({
  isSelected,
}: {
  isSelected: boolean
}): SxProps<Theme> => ({
  cursor: 'pointer',
  paddingY: '1rem',
  color: (theme) => theme.palette.primary.contrastText,
  textDecoration: 'none',
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
  letterSpacing: '0.125rem',
  display: 'flex',
  alignItems: 'center',
  transition: 'border-bottom 0.3s ease-out',
  top: '0.1rem',
  height: '3.6rem',
  position: 'relative',
  borderBottom: '0.2rem solid',
  borderColor: 'transparent',

  '&:hover': {
    borderColor: (theme) => theme.palette.primary.main,
  },

  ...(isSelected && {
    borderColor: (theme) => theme.palette.primary.main,
  }),
})
