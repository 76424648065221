import { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import Button from '../Button'

import { HOME_ANCHOR_ID } from '../../utils/types'
import { scrollToElement } from '../../utils/scroll'

import { useResponsive } from '../../hooks/useResponsive'

import {
  heroBlendImageSx,
  heroButtonSx,
  heroCopySx,
  heroDescriptionCopySx,
  heroImageBorderSx,
  heroImageSx,
  heroPictureSx,
  heroSubtitleSx,
  heroSx,
  heroTitleSx,
  heroWrapperSx,
} from './styles'

const Hero = () => {
  const { isMobile } = useResponsive()

  const handleOnSeeProjectsButtonClick = (event: React.MouseEvent) => {
    scrollToElement(event, HOME_ANCHOR_ID.PROFESSIONAL_PROJECTS)
  }

  const renderHeroDescriptionCopy = useMemo(
    () => (
      <Stack sx={heroDescriptionCopySx}>
        <Typography variant="h5" sx={heroSubtitleSx}>
          Technical Leadership, Problem Solving, SaaS, Full-Stack Development,
          Game Development, Graphics Programming
        </Typography>
        <Button
          variant="outlined"
          size="large"
          sx={heroButtonSx}
          onClick={handleOnSeeProjectsButtonClick}
        >
          See Projects
        </Button>
      </Stack>
    ),
    []
  )

  return (
    <Box sx={heroSx} id={HOME_ANCHOR_ID.ABOUT}>
      <Stack sx={heroCopySx}>
        <Stack>
          <Typography variant="h1" sx={heroTitleSx}>
            Chief
          </Typography>
          <Typography variant="h1" sx={heroTitleSx}>
            Technology
          </Typography>
          <Typography variant="h1" sx={heroTitleSx}>
            Officer.
          </Typography>
        </Stack>
        {!isMobile && renderHeroDescriptionCopy}
      </Stack>
      <Stack sx={heroPictureSx}>
        <Box sx={heroWrapperSx}>
          <Box
            alt="Jacob's profile picture"
            component="img"
            src="/profile-pic.png"
            sx={heroImageSx}
          />
          <Box component="img" src="/profile-pic.png" sx={heroBlendImageSx} />
          <Box sx={heroImageBorderSx} />
        </Box>
      </Stack>
      {isMobile && renderHeroDescriptionCopy}
    </Box>
  )
}

export default Hero
