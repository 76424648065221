import { Box, Stack, Typography } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDown } from '@fortawesome/free-regular-svg-icons'

import Button from '../../../../components/Button'
import ContactForm from '../../../../components/ContactForm'

import DownloadResume from '../../../../HOCs/DownloadResume'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import { useResponsive } from '../../../../hooks/useResponsive'

import {
  backgroundSx,
  descriptionTextSx,
  downloadResumeButtonSx,
  formWrapperSx,
  leftWrapperSx,
  wrapperSx,
} from './styles'

const Contact = () => {
  const { isMobileOrTablet } = useResponsive()

  return (
    <Box sx={backgroundSx}>
      <Stack direction={isMobileOrTablet ? 'column' : 'row'} sx={wrapperSx}>
        <Stack sx={leftWrapperSx} id={HOME_ANCHOR_ID.CONTACT}>
          <Typography variant="h1">Wanna Talk?</Typography>
          <Typography sx={descriptionTextSx}>
            I'm always excited to connect with others who share my enthusiasm
            for technology and innovation. If you're interested in learning more
            about what I do, or if you're curious about potential opportunities,
            please fill out the contact form. And if you're interested, I'd be
            happy to share my resume with you - just follow the link below to
            download it.
          </Typography>
          <DownloadResume>
            <Button
              aria-label="Download Resume"
              variant="outlined"
              size="large"
              endIcon={<FontAwesomeIcon icon={faCircleDown} />}
              sx={downloadResumeButtonSx}
            >
              Download Resume
            </Button>
          </DownloadResume>
        </Stack>
        <Stack sx={formWrapperSx}>
          <ContactForm />
        </Stack>
      </Stack>
    </Box>
  )
}

export default Contact
