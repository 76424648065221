import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'

import { useResponsive } from '../../hooks/useResponsive'

import ContactService from '../../services/contact'

import Button from '../Button'

import {
  labelSx,
  loadingWrapperSx,
  multilineSx,
  thanksMessageSx,
  thanksWrapperSx,
} from './styles'

type FormValues = {
  name: string
  email: string
  subject: string
  message: string
}

const ContactForm = () => {
  const { isMobileOrTablet } = useResponsive()

  const [isLoading, setIsLoading] = useState(false)
  const [isDisplayingThanksMessage, setIsDisplayingThanksMessage] =
    useState(false)

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(100, 'Max width reached')
      .required('Name is required'),
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required'),
    subject: Yup.string()
      .max(100, 'Max width reached')
      .required('Subject is required'),
    message: Yup.string()
      .max(500, 'Max width reached')
      .required('Message is required'),
  })

  const handleOnSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true)

      await ContactService.sendContactForm(values)

      setIsDisplayingThanksMessage(true)
      formik.resetForm()
    } catch (err) {
      console.error(err)
    }

    setIsLoading(false)
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  })

  if (isLoading) {
    return (
      <Stack sx={loadingWrapperSx}>
        <CircularProgress size={50} />
      </Stack>
    )
  }

  return (
    <Stack component="form" noValidate onSubmit={formik.handleSubmit}>
      {!isDisplayingThanksMessage ? (
        <Stack rowGap="4rem">
          <Typography variant="h4">Send Me a Message</Typography>
          <Grid container rowSpacing="4rem" columnSpacing="1.6rem">
            <Grid item xs={12} md={6}>
              <Typography sx={labelSx} variant="subtitle1">
                Name*
              </Typography>
              <TextField
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{ maxLength: 100 }}
                variant="outlined"
                placeholder="Name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={labelSx} variant="subtitle1">
                Email*
              </Typography>
              <TextField
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                placeholder="Email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={labelSx} variant="subtitle1">
                Subject*
              </Typography>
              <TextField
                id="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
                inputProps={{ maxLength: 100 }}
                variant="outlined"
                placeholder="Subject"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={labelSx} variant="subtitle1">
                Message*
              </Typography>
              <TextField
                id="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                inputProps={{ maxLength: 500, sx: multilineSx }}
                variant="outlined"
                placeholder="Message"
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth={isMobileOrTablet}
                type="submit"
                variant="contained"
                size="large"
                disabled={
                  !formik.dirty || !formik.isValid || formik.isSubmitting
                }
              >
                Send A Message
              </Button>
            </Grid>
          </Grid>
        </Stack>
      ) : (
        <Stack sx={thanksWrapperSx}>
          <Box component="img" src="/thumb-up.png" />
          <Typography sx={thanksMessageSx}>
            Thank you so much for reaching out to me. I truly appreciate your
            message and am grateful for the time you took to connect. I’ll be
            getting in tough as soon as possible.
          </Typography>
          <Button
            variant="contained"
            size="medium"
            onClick={() => setIsDisplayingThanksMessage(false)}
          >
            Send Another Message?
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

export default ContactForm
