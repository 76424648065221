import { SxProps, Theme } from '@mui/material'

export const appBarSx = ({
  isMenuOpen,
}: {
  isMenuOpen: boolean
}): SxProps<Theme> => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: { xs: 'space-between', lg: 'flex-start' },
  padding: { xs: '2.6rem 2.4rem', lg: '3.2rem 2.4rem' },
  background: (theme) => theme.palette.primary.dark,
  opacity: 0.95,
  backdropFilter: 'blur(20px)',
  boxShadow: 'none',
  transition: 'opacity 0.3s ease-in-out',

  ...(isMenuOpen && {
    opacity: 0,
  }),
})
