import { SxProps, Theme } from '@mui/material'

export const labelSx: SxProps<Theme> = {
  marginBottom: '1.2rem',
  fontWeight: 400,
}

export const multilineSx: SxProps<Theme> = {
  minHeight: '6.7rem',
}

export const thanksWrapperSx: SxProps<Theme> = {
  padding: '4rem',
  gap: '1.6rem',
  justifyContent: 'center',
  alignItems: 'center',
}

export const thanksMessageSx: SxProps<Theme> = {
  fontWeight: 400,
  fontSize: '2.2rem',
  lineHeight: '4.2rem',
  textAlign: 'center',
}

export const loadingWrapperSx: SxProps<Theme> = {
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
}
