export enum HOME_ANCHOR_ID {
  ABOUT = 'about',
  PROFESSIONAL_PROJECTS = 'professional-projects',
  PERSONAL_PROJECTS = 'personal-projects',
  CONTACT = 'contact',
}

export interface IContactForm {
  name: string
  email: string
  subject: string
  message: string
}
