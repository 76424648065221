import { Stack, Typography } from '@mui/material'

import FeaturedItem from '../../../../components/FeaturedItem'
import { IFeaturedItemProps } from '../../../../components/FeaturedItem/FeaturedItem'

import { useResponsive } from '../../../../hooks/useResponsive'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import { itemsWrapperSx, wrapperSx } from './styles'

const ProfessionalProjects = () => {
  const { isMobileOrTablet } = useResponsive()

  const PROFESSIONAL_PROJECTS_LIST: IFeaturedItemProps[] = [
    {
      title: 'Technology Match',
      company: 'The Launchpad',
      domains: ['Leadership', 'Infrastructure'],
      technologies: ['AWS', 'Beanstalk', 'CDK', 'Stripe', 'PostgreSQL', 'Jira'],
      position: 'Co-Founder & CTO',
      description: (
        <Stack spacing="2.4rem">
          <Typography variant="subtitle1">
            Technology Match is a SaaS technology matchmaking platform that is
            transforming the way technology is marketed, purchased, and sold.
            This multi-year project was recently launched in its Beta version in
            January 2023. I played a critical role in bringing this project to
            life, from its conceptualization to its development. I was
            responsible for building the team and personally interviewing and
            hiring designers, project managers, and software engineers. I
            collaborated closely with the entire team to ensure the platform was
            developed as efficiently and effectively as possible.
          </Typography>
          <Typography variant="subtitle1">
            In addition to managing the team, I was hands-on with the
            infrastructure, specifically in putting the production stack
            together for the beta launch. The platform continues to evolve
            daily, with over a year's worth of features and improvements on the
            roadmap. I am excited to continue working on this project and
            bringing new, innovative solutions to the technology matchmaking
            space.
          </Typography>
        </Stack>
      ),
      featuredImage: 'technology-match.png',
      url: 'https://technologymatch.com/',
    },
    {
      title: 'Launchpad Outreach',
      company: 'The Launchpad',
      domains: ['Leadership', 'Infrastructure'],
      technologies: [
        'AWS',
        'C# .Net Core',
        'EC2',
        'SQL Server',
        'Cloud Formation',
      ],
      position: 'Co-Founder & CTO',
      description: (
        <Typography variant="subtitle1">
          A powerful marketing tool that I designed and developed, contributing
          to our company's rapid growth from $0 to over $5,000,000 in revenue
          within two years. The platform would source contact information from
          various places such as DiscoverOrg, Aberdeen, Lead411 and Zoom Info.
          It would then ingest a single HTML email and dispatch it through
          multiple email services such as Sendgrid, Constant Contact, and Amazon
          SES. I integrated safety features to prevent duplicated content and
          ensure each recipient receives only one email every two weeks. These
          measures minimized the risk of human error and facilitated efficient
          email marketing.
        </Typography>
      ),
      featuredImage: 'launchpad-outreach.png',
    },
    {
      title: 'The Launchpad Data',
      company: 'The Launchpad',
      domains: ['Leadership', 'Infrastructure'],
      technologies: [
        'AWS',
        'Serverless',
        'C# .Net Core',
        'SQL Server',
        'React',
        'Stripe',
      ],
      position: 'Co-Founder & CTO',
      description: (
        <Typography variant="subtitle1">
          A platform designed to empower companies with tailored and
          high-quality data to drive their marketing strategies. I was the sole
          developer and brought this project to life by enabling users to
          effortlessly query for desired contacts based on job function, job
          title, location, and installed technologies. The platform offered a
          streamlined solution to enhance marketing efforts.
        </Typography>
      ),
      featuredImage: 'launchpad-data.png',
    },
    {
      title: 'Backoffice',
      company: 'Gnarlysoft',
      clients: ['The Canadian Medstore', 'Discount Med Direct'],
      domains: ['Web', 'Full Stack'],
      technologies: ['AWS', 'React', 'ASP.NET Core', 'Postgres', 'Serverless'],
      position: 'Founder',
      description: (
        <Stack spacing="2.4rem">
          <Typography variant="subtitle1">
            A comprehensive CRM platform developed for Canadian Medstore (CMS)
            and Discount Med Direct (DMD), two online pharmacies. The platform
            provides a solution for tracking and managing client data, drug
            prices, employee onboarding, branch onboarding, ordering, and
            invoicing. I served as the sole owner/designer/developer for this
            project.
          </Typography>
          <Typography variant="subtitle1">
            The first iteration was built using the latest technology such as
            AWS lambdas for the API and Dynamo DB for the database, but
            performance issues arose due to slow cold boot times and the need
            for data aggregation. I attempted to resolve these issues by
            integrating Elasticsearch into the stack, but the monthly costs of
            this solution were not cost-effective. I then moved the data to a
            PostgreSQL database and ported the lambda operations to an ASP.NET
            application running on Beanstalk, resulting in a more efficient and
            cost-effective platform that has processed millions of dollars'
            worth of orders.
          </Typography>
        </Stack>
      ),
      featuredImage: 'backoffice.png',
    },
    {
      title: 'Madden 2019 | NBA | PGA Tour',
      company: 'Electronic Arts',
      domains: ['Graphics', 'Generalist'],
      technologies: [
        'PS4',
        'Xbox One',
        'Razer CPU/GPU',
        'Pix',
        'C++',
        'HLSL',
        'Frostbite',
      ],
      position: 'Graphics Software Engineer',
      description: (
        <Typography variant="subtitle1">
          As a skilled software engineer, I contributed to the development of
          three popular video games: Madden 2019, NBA, and PGA Tour. My role
          involved optimizing the performance and memory of scenes in the Madden
          2019 "Longshot" story mode to ensure smooth and seamless gameplay. I
          also implemented async compute on Xbox One and PS4 for the NBA game to
          enhance its visual quality and performance. In PGA Tour, I played a
          crucial role in resolving GPU hangs and improving various visual
          effects. These projects provided me with the opportunity to
          demonstrate my expertise in debugging and optimization.
        </Typography>
      ),
      featuredImage: 'madden-2019.png',
      url: 'https://www.ea.com/games/madden-nfl',
    },
    {
      title: 'Madden 2018',
      company: 'Electronic Arts',
      domains: ['Graphics', 'Memory', 'Performance', 'Leadership'],
      technologies: [
        'PS4',
        'Xbox One',
        'Razer CPU/GPU',
        'Pix',
        'C++',
        'HLSL',
        'Frostbite',
      ],
      position: 'Lead Graphics Software Engineer',
      description: (
        <Typography variant="subtitle1">
          As the Graphics Lead on Madden 2018's Longshot story mode, I was
          instrumental in successfully shipping the game on the Frostbite
          engine. I led my team in overcoming the challenges posed by the new
          technology, including performance and memory issues resulting from
          artists' freedom in creating environments. To tackle these issues, I
          developed and implemented automated tools to monitor and report on
          memory and performance usage, allowing us to effectively manage the
          high volume of frequently changing scenes.
        </Typography>
      ),
      featuredImage: 'madden-2018.png',
      url: 'https://www.ea.com/games/madden-nfl',
    },
    {
      title: 'Madden 2017',
      company: 'Electronic Arts',
      domains: ['Graphics', 'Memory', 'Performance', 'Leadership'],
      technologies: ['PS4', 'Xbox One', 'Razer CPU/GPU', 'Pix', 'C++', 'HLSL'],
      position: 'Lead Graphics Software Engineer',
      description: (
        <Typography variant="subtitle1">
          As the Graphics Lead for Madden 2017, I was responsible for overseeing
          the smooth operation of the project and ensuring the delivery of
          high-quality work by my team. My focus was to protect my team from
          unplanned tasks and handle critical issues such as bugs, crashes, and
          deadlocks. This approach led to a successful project outcome and my
          promotion. This experience highlights my leadership skills and ability
          to handle challenging situations effectively.
        </Typography>
      ),
      featuredImage: 'madden-2017.png',
      url: 'https://www.ea.com/games/madden-nfl',
    },
    {
      title: 'Madden 2015 | Madden 2016',
      company: 'Electronic Arts',
      domains: ['Graphics', 'Memory', 'Performance'],
      technologies: ['PS4', 'Xbox One', 'Razer CPU/GPU', 'Pix', 'C++'],
      position: 'Graphics Software Engineer',
      description: (
        <Typography variant="subtitle1">
          As the owner of performance and memory, I was accountable for
          maintaining the game's smooth 60fps and keeping each domain within
          their memory budgets. I showcased my graphics programming and
          optimization skills by making several graphics improvements,
          implementing a pixel shader-based fog algorithm, and improving the
          performance of the secondary animation system. I also built a "hair
          priority" system, enabling designers to prioritize high-quality hair
          for certain player positions, and tracked down facial animation bugs
          by utilizing the stream-output stage on the GPU. These projects
          highlight my technical abilities and attention to detail.
        </Typography>
      ),
      featuredImage: 'madden-2015-2016.png',
      url: 'https://www.ea.com/games/madden-nfl',
    },
    {
      title: 'Madden 25',
      company: 'Electronic Arts',
      domains: [
        'Graphics',
        'Animation',
        'Memory',
        'Performance',
        'Next-Gen Port Team',
      ],
      technologies: ['PS4', 'Xbox One', 'Razer CPU/GPU', 'Pix', 'C++', 'HLSL'],
      position: 'Graphics Software Engineer',
      description: (
        <Typography variant="subtitle1">
          As a member of a three-person team tasked with porting Madden to
          next-gen hardware, I played a critical role in ensuring the success of
          this project. Working under strict NDA, I dedicated many sleepless
          nights to debugging shader disassembly and reporting compiler bugs to
          Microsoft. This experience allowed me to experiment with new hardware
          features and showcase my technical skills. I was also responsible for
          adding secondary animation to player hair and towels using cloth
          physics, which I had the privilege of presenting to Andrew Wilson, the
          CEO of Electronic Arts. Beyond the technical aspect, this project
          holds a special place in my heart as it was during this time that I
          met my future wife, who initially thought I was a spy due to the
          secrecy surrounding the project.
        </Typography>
      ),
      featuredImage: 'madden-25.png',
      url: 'https://www.ea.com/games/madden-nfl',
    },
    {
      title: 'NCAA 2014',
      company: 'Electronic Arts',
      domains: ['Graphics', 'Facial Animation', 'Memory', 'Performance'],
      technologies: ['PS3', 'Xbox 360', 'Razer CPU/GPU', 'Pix', 'C++', 'HLSL'],
      position: 'Graphics Software Engineer',
      description: (
        <Typography variant="subtitle1">
          As a contributor to NCAA 2014, I supported the team in achieving
          successful results by implementing facial animation for the hero
          character Kirk Herbstreit. Additionally, I made optimizations in both
          memory and performance, ensuring the game ran smoothly and
          efficiently. These efforts reflect my technical skills in graphics
          programming and optimization.
        </Typography>
      ),
      featuredImage: 'ncaa-2014.png',
      url: 'https://www.ea.com/games/madden-nfl',
    },
    {
      title: 'NCAA 2013 | Madden 2013',
      company: 'Electronic Arts',
      domains: ['Graphics', 'Facial Animation', 'Memory'],
      technologies: ['PS3', 'Xbox 360', 'Razer CPU/GPU', 'Pix', 'C++', 'HLSL'],
      position: 'Graphics Software Engineer',
      description: (
        <Typography variant="subtitle1">
          As the owner of facial animation for both NCAA and Madden, I played a
          crucial role in ensuring the smooth implementation of the system. I
          was responsible for supporting the hero characters in Madden, Jim
          Nantz, and Phil Simms, and made sure the system could handle their
          animations effectively. Additionally, I was known as the go-to person
          for resolving memory crashes and optimizations, further highlighting
          my technical skills.
        </Typography>
      ),
      featuredImage: 'ncaa-madden-2013.png',
      url: 'https://www.ea.com/games/madden-nfl',
    },
    {
      title: 'NCAA 2012 | Madden 2012',
      company: 'Electronic Arts',
      domains: ['Graphics', 'Animation'],
      technologies: ['PS3', 'Xbox 360', 'Kinect', 'C++', 'C#'],
      position: 'Tools / Graphics Software Engineer',
      description: (
        <Typography variant="subtitle1">
          As a Tools Software Engineer at EA, I supported the Core Graphics team
          responsible for rendering on both NCAA12 and Madden12. I made
          contributions by building various plugins for ANT (EA's internal
          animation tool kit), including the "Kinect" plugin that was eventually
          adopted by multiple games across EA. After several months, I was
          converted to a Graphics Software Engineer, aligning with my passion
          for graphics programming. These projects showcase my versatility and
          technical skills in both tools and graphics software engineering.
        </Typography>
      ),
      featuredImage: 'ncaa-madden-2012.png',
      url: 'https://www.ea.com/games/madden-nfl',
    },
  ]

  return (
    <Stack sx={wrapperSx}>
      <Typography variant="h1" id={HOME_ANCHOR_ID.PROFESSIONAL_PROJECTS}>
        Professional Projects
      </Typography>
      <Stack sx={itemsWrapperSx}>
        {PROFESSIONAL_PROJECTS_LIST.map((v, i) => {
          const variant = i % 2 === 0 || isMobileOrTablet ? 'left' : 'right'
          return <FeaturedItem key={v.title} variant={variant} {...v} />
        })}
      </Stack>
    </Stack>
  )
}

export default ProfessionalProjects
