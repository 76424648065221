import { Box } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDown } from '@fortawesome/free-regular-svg-icons'

import Button from '../../../Button'
import NavigationItem from '../NavigationItem'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import DownloadResume from '../../../../HOCs/DownloadResume'

import { downloadButtonSx, navSx, wrapperSx } from './styles'

interface IExpandedMenuProps {
  activeSection: HOME_ANCHOR_ID
  onNavigationItemClicked?: () => void
}

const ExpandedMenu = ({
  activeSection,
  onNavigationItemClicked,
}: IExpandedMenuProps) => {
  return (
    <Box sx={wrapperSx}>
      <Box
        component="nav"
        display="flex"
        gap="2.4rem"
        flex="1"
        justifyContent="flex-end"
        sx={navSx}
      >
        <NavigationItem
          aria-label="About"
          label="About"
          elementId={HOME_ANCHOR_ID.ABOUT}
          isSelected={activeSection === HOME_ANCHOR_ID.ABOUT}
          onClick={() => onNavigationItemClicked?.()}
        />
        <NavigationItem
          aria-label="Professional Projects"
          label="Professional Projects"
          elementId={HOME_ANCHOR_ID.PROFESSIONAL_PROJECTS}
          isSelected={activeSection === HOME_ANCHOR_ID.PROFESSIONAL_PROJECTS}
          onClick={() => onNavigationItemClicked?.()}
        />
        <NavigationItem
          aria-label="Personal Projects"
          label="Personal Projects"
          elementId={HOME_ANCHOR_ID.PERSONAL_PROJECTS}
          isSelected={activeSection === HOME_ANCHOR_ID.PERSONAL_PROJECTS}
          onClick={() => onNavigationItemClicked?.()}
        />
        <NavigationItem
          aria-label="Contact"
          label="Contact"
          elementId={HOME_ANCHOR_ID.CONTACT}
          isSelected={activeSection === HOME_ANCHOR_ID.CONTACT}
          onClick={() => onNavigationItemClicked?.()}
        />
      </Box>
      <DownloadResume>
        <Button
          aria-label="Download Resume"
          variant="outlined"
          sx={downloadButtonSx}
          endIcon={<FontAwesomeIcon icon={faCircleDown} />}
        >
          Download Resume
        </Button>
      </DownloadResume>
    </Box>
  )
}

export default ExpandedMenu
