import axios from 'axios'

import { IContactForm } from '../utils/types'

const sendContactForm = async (data: IContactForm): Promise<any> => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_SUBMIT_FORM_API_URL!,
      data,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )

    return response.data
  } catch (err) {
    throw err
  }
}

const exports = {
  sendContactForm,
}

export default exports
