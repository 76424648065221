import { Box, useTheme } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons'

import { scrollToTop } from '../../utils/scroll'

import { iconSx, iconWrapperSx } from './styles'
import { useEffect, useState } from 'react'

const ScrollToTopButton = () => {
  const theme = useTheme()

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  function handleScroll() {
    if (window.scrollY > window.innerHeight * 0.2) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const handleOnScrollToTop = () => {
    scrollToTop()
  }

  return (
    <Box sx={iconWrapperSx({ isVisible })} onClick={handleOnScrollToTop}>
      <FontAwesomeIcon icon={faCircleArrowUp} size="2x" style={iconSx(theme)} />
    </Box>
  )
}

export default ScrollToTopButton
