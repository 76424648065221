import { SxProps, Theme } from '@mui/material'
import { CSSProperties } from 'react'

export const iconButtonStyles = (theme: Theme): CSSProperties => ({
  color: theme.palette.primary.light,
})

export const dialogSx: SxProps<Theme> = {
  position: 'sticky',
}

export const menuPaperSx: SxProps<Theme> = {
  position: 'relative',
  height: '100vh',
  opacity: 0.9,
  background: (theme) => theme.palette.primary.dark,
}

export const closeIconWrapperSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '2.4rem',
  color: (theme) => theme.palette.primary.main,
}

export const logoWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 1.6rem',
}
