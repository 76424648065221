import { Link } from '@mui/material'
import { scrollToElement } from '../../../../utils/scroll'

import { navigationItemSx } from './styles'

interface INavigationItemProps {
  elementId: string
  label: string
  isSelected: boolean
  onClick: () => void
}

const NavigationItem = ({
  label,
  elementId,
  isSelected,
  onClick,
}: INavigationItemProps) => {
  const handleOnClick = (event: React.MouseEvent) => {
    onClick()
    scrollToElement(event, elementId)
  }

  return (
    <Link onClick={handleOnClick} sx={navigationItemSx({ isSelected })}>
      {label}
    </Link>
  )
}

export default NavigationItem
