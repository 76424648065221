import { createContext, PropsWithChildren, useCallback, useState } from 'react'

export type MenuContextState = {
  isMenuOpen: boolean
  onMenuClick: () => void
  onMenuClose: () => void
}

export const MenuContext = createContext<MenuContextState>(
  {} as MenuContextState
)

const MenuProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOnMenuClick = () => {
    setIsOpen((curr) => !curr)
  }

  const isMenuOpen = isOpen

  const onMenuClick = useCallback(handleOnMenuClick, [])

  const onMenuClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        onMenuClick,
        onMenuClose,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export default MenuProvider
