import React, {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  useMemo,
} from 'react'

import FileUtils from '../../utils/file'

const DownloadResume: React.FC<PropsWithChildren> = ({ children }) => {
  const handleOnDownloadResume = async () => {
    const response = await fetch('/Jacob-Kapostins-Resume.pdf')
    const buffer = await response.arrayBuffer()

    FileUtils.download({
      buffer,
      filename: 'Jacob-Kapostins-Resume.pdf',
      contentType: 'application/pdf',
    })
  }

  const ClonedElement = useMemo(
    () =>
      cloneElement(children as ReactElement, {
        onClick: handleOnDownloadResume,
      }),
    [children]
  )

  return ClonedElement
}

export default DownloadResume
