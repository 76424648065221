import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Box, Chip, Link, Stack, Typography } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

import { useResponsive } from '../../hooks/useResponsive'

import {
  borderSx,
  chipSx,
  contentSx,
  copyContentSx,
  imageSx,
  imageWrapperSx,
  overlaySx,
  subtitlesWrapperSx,
  subtitleSx,
  subtitleWrapperSx,
  wrapperSx,
} from './styles'
import PopupImage from '../PopupImage'

export interface IFeaturedItemProps {
  title: string
  company?: string
  clients?: string[]
  domains: string[]
  technologies: string[]
  sources?: {
    label: string
    url: string
  }[]
  position?: string
  description: ReactNode
  featuredImage: string
  variant?: 'left' | 'right'
  url?: string
}

const FeaturedItem = ({
  title,
  company,
  clients,
  domains,
  technologies,
  sources,
  position,
  description,
  featuredImage,
  variant = 'left',
  url,
}: IFeaturedItemProps) => {
  const { isMobile } = useResponsive()

  const [popupImage, setPopupImage] = useState('')

  const handleOnRedirectToExternalPage = useCallback(() => {
    window.open(url, '_blank')
  }, [url])

  const handleOnOpenPopupImage = useCallback(() => {
    if (!!url) return

    setPopupImage(featuredImage)
  }, [featuredImage, url])

  const renderFeaturedImage = useMemo(
    () => (
      <Stack sx={imageWrapperSx}>
        <Box sx={imageSx({ featuredImage })} onClick={handleOnOpenPopupImage} />
        {!!url && (
          <Box sx={overlaySx} onClick={handleOnRedirectToExternalPage}>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="2x" />
            <Typography variant="h6">See Website</Typography>
          </Box>
        )}
      </Stack>
    ),
    [featuredImage, url, handleOnOpenPopupImage, handleOnRedirectToExternalPage]
  )

  return (
    <>
      <PopupImage imageSrc={popupImage} onClose={() => setPopupImage('')} />

      <Box sx={wrapperSx}>
        <Stack sx={contentSx}>
          {variant === 'right' && renderFeaturedImage}
          <Stack sx={copyContentSx}>
            <Typography variant="h3">{title}</Typography>
            <Stack sx={subtitlesWrapperSx}>
              {!!company && (
                <Stack direction="row" sx={subtitleWrapperSx}>
                  <Typography variant="subtitle2" sx={subtitleSx}>
                    Company:
                  </Typography>
                  <Typography variant="subtitle2">{company}</Typography>
                </Stack>
              )}
              {!!clients && (
                <Stack direction="row" sx={subtitleWrapperSx}>
                  <Typography variant="subtitle2" sx={subtitleSx}>
                    Clients:
                  </Typography>
                  <Typography variant="subtitle2">
                    {clients.map((v, i) => {
                      if (i === clients.length - 1) {
                        return v
                      }
                      return `${v} | `
                    })}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" sx={subtitleWrapperSx}>
                <Typography variant="subtitle2" sx={subtitleSx}>
                  Domains:
                </Typography>
                <Typography variant="subtitle2">
                  {domains.map((v, i) => {
                    if (i === domains.length - 1) {
                      return v
                    }
                    return `${v} | `
                  })}
                </Typography>
              </Stack>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                sx={subtitleWrapperSx}
              >
                <Typography variant="subtitle2" sx={subtitleSx}>
                  Notable Technologies:
                </Typography>
                <Stack direction={isMobile ? 'column' : 'row'} spacing="1rem">
                  {technologies.map((v) => (
                    <Chip key={v} label={v} sx={chipSx} />
                  ))}
                </Stack>
              </Stack>
              {!!sources && (
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  sx={subtitleWrapperSx}
                >
                  <Typography variant="subtitle2" sx={subtitleSx}>
                    Sources:
                  </Typography>
                  <Typography variant="subtitle2">
                    {sources.map((v, i) => {
                      if (i === sources.length - 1) {
                        return (
                          <Link href={v.url} target="_blank">
                            {v.label}
                          </Link>
                        )
                      }
                      return (
                        <>
                          <Link href={v.url} target="_blank">
                            {v.label}
                          </Link>
                          {' | '}
                        </>
                      )
                    })}
                  </Typography>
                </Stack>
              )}
            </Stack>
            {!!position && <Typography variant="body2">{position}</Typography>}
            <Typography variant="subtitle1">{description}</Typography>
          </Stack>
          {variant === 'left' && renderFeaturedImage}
        </Stack>
        <Box sx={borderSx(variant)} />
      </Box>
    </>
  )
}

export default FeaturedItem
