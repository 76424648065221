import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  position: 'relative',
  marginTop: '3.2rem',
  marginLeft: { md: '9rem', lg: '14.2rem' },
}

export const contentSx: SxProps<Theme> = {
  position: 'relative',
  background: 'rgba(30, 40, 60, 0.85)',
  backdropFilter: 'blur(3px)',
  padding: { xs: '2.4rem', lg: '3.2rem' },
  rowGap: '3.2rem',
}

export const featuredCopySx: SxProps<Theme> = {
  fontSize: { xs: '2rem', md: '2.4rem' },
}

export const borderSx: SxProps<Theme> = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: { xs: '1.6rem', md: '3rem' },
  right: { xs: '1.6rem', md: '3rem' },
  border: '2px solid',
  borderImage: (theme) =>
    `linear-gradient(135deg, ${theme.palette.secondary.dark}, ${theme.palette.primary.main}) 1`,
  borderImageSlice: 1,
  zIndex: -1,
}
