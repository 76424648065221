import { useEffect, useState } from 'react'
import { AppBar } from '@mui/material'

import { useResponsive } from '../../hooks/useResponsive'
import { useMenu } from '../../hooks/useMenu'

import Logo from '../Logo'
import CollapsedMenu from './components/CollapsedMenu'
import ExpandedMenu from './components/ExpandedMenu'

import { HOME_ANCHOR_ID } from '../../utils/types'

import { appBarSx } from './styles'

const Navbar = () => {
  const { isMobileOrTablet } = useResponsive()
  const { isMenuOpen } = useMenu()

  const [activeSection, setActiveSection] = useState(HOME_ANCHOR_ID.ABOUT)

  const handleScroll = () => {
    const sectionIds = Object.values(HOME_ANCHOR_ID)
    let activeSectionId = ''

    sectionIds.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        const { top } = element.getBoundingClientRect()
        const sectionTop = top + window.pageYOffset

        if (
          window.pageYOffset + window.innerHeight ===
          document.documentElement.scrollHeight
        ) {
          activeSectionId = sectionIds[sectionIds.length - 1]
        } else if (window.pageYOffset >= sectionTop - 246.5) {
          activeSectionId = id
        }
      }
    })

    setActiveSection(activeSectionId as HOME_ANCHOR_ID)

    if (activeSectionId) {
      const href = `#${activeSectionId}`
      if (window.location.hash !== href) {
        window.history.replaceState(null, '', href)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    if (window.location.hash) {
      setTimeout(() => {
        window.removeEventListener('scroll', handleScroll)

        const hash = window.location.hash.substring(1)
        const element = document.getElementById(hash)

        if (element) {
          const yCoordinate =
            element.getBoundingClientRect().top + window.pageYOffset
          const yOffset = -120
          setActiveSection(hash as HOME_ANCHOR_ID)
          window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
        }

        setTimeout(() => {
          window.addEventListener('scroll', handleScroll)
        }, 1000)
      }, 500)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <AppBar position="sticky" sx={appBarSx({ isMenuOpen })}>
      <Logo />
      {isMobileOrTablet ? (
        <CollapsedMenu activeSection={activeSection} />
      ) : (
        <ExpandedMenu activeSection={activeSection} />
      )}
    </AppBar>
  )
}

export default Navbar
