import { Backdrop, Box, Fade, Modal } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

import {
  closeIconWrapperSx,
  imageSx,
  innerWrapperSx,
  modalSx,
  wrapperSx,
} from './styles'

interface IPopupImageProps {
  imageSrc: string
  onClose: () => void
}

const PopupImage = ({ imageSrc, onClose }: IPopupImageProps) => {
  const open = !!imageSrc

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableAutoFocus
      disablePortal
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={modalSx}
    >
      <Fade in={open} timeout={500}>
        <Box sx={wrapperSx}>
          <Box sx={innerWrapperSx}>
            <Box sx={closeIconWrapperSx} onClick={onClose}>
              <FontAwesomeIcon icon={faClose} />
            </Box>
            <Box component="img" src={imageSrc} sx={imageSx} />
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default PopupImage
