import { Box, Container, Stack } from '@mui/material'

import Navbar from '../../components/Navbar'
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton'
import Hero from '../../components/Hero'
import WhatIsThis from '../../components/WhatIsThis'
import Personal from './components/Personal'
import ProfessionalProjects from './components/ProfessionalProjects'
import Contact from './components/Contact'

import { wrapperSx, purpleRightBackgroundSx, containerSx } from './styles'

const Home = () => (
  <>
    <Navbar />
    <Container sx={containerSx}>
      <Box sx={purpleRightBackgroundSx} />
      <Stack sx={wrapperSx}>
        <Hero />
        <WhatIsThis />
        <ProfessionalProjects />
        <Personal />
      </Stack>
    </Container>
    <Contact />
    <ScrollToTopButton />
  </>
)

export default Home
