import { SxProps, Theme } from '@mui/material'
import { CSSProperties } from 'react'

export const iconWrapperSx = ({
  isVisible,
}: {
  isVisible: boolean
}): SxProps<Theme> => ({
  position: 'sticky',
  right: '2.4rem',
  bottom: '2.4rem',
  maxWidth: 'fit-content',
  maxHeight: '4rem',
  left: 'calc(100% - 6.4rem)',
  cursor: 'pointer',
  zIndex: 1000,
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',

  ...(isVisible && {
    opacity: 1,
  }),
})

export const iconSx = (theme: Theme): CSSProperties => ({
  color: theme.palette.primary.main,
})
