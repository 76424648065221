import { PropsWithChildren } from 'react'

import ThemeProvider from './theme'
import MenuProvider from './menu'

const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider>
      <MenuProvider>{children}</MenuProvider>
    </ThemeProvider>
  )
}

export default AppProvider
