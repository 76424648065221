import { SxProps, Theme } from '@mui/material'

export const buttonSx = ({
  variant,
}: {
  variant: 'text' | 'outlined' | 'contained' | undefined
}): SxProps<Theme> => {
  const fullFilledStyles: SxProps<Theme> = {
    color: (theme) => theme.palette.primary.contrastText,
    borderImage: (theme) =>
      `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.dark}) 1`,
    borderImageSlice: 1,
  }

  if (variant === 'outlined') {
    return {
      position: 'relative',
      background: 'transparent',
      transition: 'color .3s ease-in-out',
      border: '1px solid',
      borderImage: (theme) =>
        `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.dark}) 1`,
      borderImageSlice: 1,
      borderRadius: 0,
      zIndex: 2,

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: (theme) =>
          `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
        opacity: 0,
        transition: 'opacity .3s ease-out',
        zIndex: -1,
      },

      '&:hover': {
        ...fullFilledStyles,
        '&:after': {
          opacity: 1,
        },
      },
    }
  }

  if (variant === 'contained') {
    return {
      ...fullFilledStyles,
      position: 'relative',
      background: 'transparent',
      transition: 'color .3s ease-in-out',
      border: 'none',
      borderRadius: 0,
      zIndex: 2,
      boxShadow: 'none',

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: (theme) =>
          `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.dark} 100%)`,
        opacity: 1,
        transition: 'opacity .3s ease-out',
        zIndex: -1,
      },

      ':disabled': {
        background: (theme) => theme.palette.grey['300'],
        color: (theme) => theme.palette.common.white,

        '&:after': {
          opacity: 0,
        },
      },

      '&:hover': {
        boxShadow: 'none',
      },
    }
  }

  return {}
}
