import { SxProps, Theme } from '@mui/material'

export const containerSx: SxProps<Theme> = {
  position: 'relative',
  maxWidth: {
    lg: '139.6rem',
  },
}

export const purpleRightBackgroundSx: SxProps<Theme> = {
  position: 'absolute',
  width: { xs: '13.7rem', md: '26.6rem', lg: '71rem' },
  height: 'calc(100% + 25.5rem)',
  top: '-11.2rem',
  bottom: 0,
  background:
    'linear-gradient(89.25deg, rgba(115, 55, 242, 0.75) 1.2%, rgba(161, 125, 237, 0.75) 99.91%)',
  boxShadow: '0 0.4rem 0.4rem rgba(0, 0, 0, 0.25)',
  backdropFilter: 'blur(0.3rem)',
  transform: 'matrix(-1, 0, 0, 1, 0, 0)',
  zIndex: -1,
  right: 0,

  '@media (min-width: 1700px)': {
    right: '-14rem',
  },
}

export const wrapperSx: SxProps<Theme> = {
  gap: { xs: '5.4rem', md: '6.3rem', lg: '11.1rem' },
}
