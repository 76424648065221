import { Stack, Typography } from '@mui/material'

import FeaturedItem from '../../../../components/FeaturedItem'
import { IFeaturedItemProps } from '../../../../components/FeaturedItem/FeaturedItem'

import { useResponsive } from '../../../../hooks/useResponsive'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import { itemsWrapperSx, wrapperSx } from './styles'

const Personal = () => {
  const { isMobileOrTablet } = useResponsive()

  const PERSONAL_LIST: IFeaturedItemProps[] = [
    {
      title: 'Motorcoach Toilet',
      domains: ['Hardware'],
      technologies: ['Arduino', 'Nextion HMI Display', 'C++'],
      description: (
        <Typography variant="subtitle1">
          I own a Newmar Ventana LE and the toilets are controlled by
          technology. The original control module, which controlled the flush
          and water fill functions, was damaged due to exposure to a corrosive
          substance and a replacement cost $800. To save money and demonstrate
          my technical aptitude, I built my own control module using an Arduino
          Nano, relays, and a touch screen display. The project took only a few
          hours to complete and cost me around $80. I faced a few
          hardware-related challenges but was able to diagnose and solve them
          quickly using a multimeter. The touch screen display significantly
          improved the original set of buttons and added sophistication to the
          motorcoach toilet. This project showcased my hands-on experience and
          passion for technology.
        </Typography>
      ),
      featuredImage: 'motorcoach-toilet.png',
    },
    {
      title: 'Reflex Track Manager',
      domains: ['Games', 'Modding'],
      technologies: ['AWS', 'Lambda', 'DynamoDB', 'ImGUI', 'gRPC', 'C++', 'C#'],
      sources: [
        {
          label: 'Github',
          url: 'https://github.com/JKapostins/Reflex-TrackManager',
        },
        {
          label: 'Youtube',
          url: 'https://www.youtube.com/watch?v=geYVYDgimpE',
        },
      ],
      description: (
        <Stack spacing="2.4rem">
          <Typography variant="subtitle1">
            I am an avid fan of motocross, and MX vs. ATV Reflex is one of my
            favorite games. I noticed a tedious process for installing custom
            tracks within the game, so I created the "Reflex Track Manager" to
            simplify the process. The tool scans track websites and repositories
            and copies them to my S3 bucket, referenced in DynamoDB on a nightly
            basis.
          </Typography>
          <Typography variant="subtitle1">
            The client consists of two parts: a C++ DLL that hooks the game's
            render function and displays an overlay built with ImGUI, and a C#
            .NET Core command line application that performs the injection of
            the overlay DLL into the Reflex process. The C++ DLL running inside
            the Reflex process communicates with the C# CLI tool via gRPC. The
            user selects a track from the overlay, and the C# process checks the
            local HDD to see if the track has already been downloaded. If it
            exists, it will unzip and copy it to a special folder that the
            Reflex process can access. If not, it will query the database and
            download it from S3.
          </Typography>
          <Typography variant="subtitle1">
            This mod has been successful with over 15,000 downloads to date,
            showcasing my technical skills in software development, and my
            passion for gaming and the motocross community.
          </Typography>
        </Stack>
      ),
      featuredImage: 'reflex-track-manager.png',
    },
    {
      title: 'Gnarly Trader',
      domains: ['Desktop', 'Finance', 'Trading'],
      technologies: ['Interactive Brokers API', 'Qt', 'C++'],
      description: (
        <Stack spacing="2.4rem">
          <Typography variant="subtitle1">
            As an avid stock trader, I found myself constantly frustrated by the
            limitations of the trading platform provided by Interactive Brokers.
            I had a fast-paced trading style that required quick and efficient
            execution of trades, but their platform was not designed for this
            type of use.
          </Typography>
          <Typography variant="subtitle1">
            To solve this problem, I built a custom trading interface using C++
            and the Qt framework. The interface was designed specifically to
            support the use of hotkeys, which allowed me to execute trades with
            speed and precision. I utilized Interactive Brokers' C++ API to
            communicate my trade requests to their servers. This project allowed
            me to streamline my trading process and execute trades with ease,
            ultimately improving my performance as a day trader.
          </Typography>
        </Stack>
      ),
      featuredImage: 'gnarly-trader.png',
    },
    {
      title: 'Discord Client',
      company: 'Gnarlysoft',
      domains: ['Mobile', 'Chat'],
      technologies: ['Discord API', 'UWP', 'C#', 'Windows Phone'],
      position: 'Founder',
      description: (
        <Typography variant="subtitle1">
          As an avid user of Discord and owner of a Windows Phone, I noticed a
          lack of a mobile client for this platform. To fill this gap, I built a
          Discord client for Windows Phone using the Discord API and Universal
          Windows Platform (UWP). Despite its limited target audience, I
          released it for free on the Windows Store and was pleased to see it
          receive approximately 80,000 downloads during its time on the store.
          This project showcased my ability to quickly learn and utilize new
          APIs, as well as my passion for providing solutions for everyday
          problems. Although the Windows Phone market is no longer active, this
          project remains a testament to my technical abilities and creative
          problem-solving skills.
        </Typography>
      ),
      featuredImage: 'discord-client.png',
    },
    {
      title: 'Mototime',
      company: 'Gnarlysoft',
      domains: ['Mobile'],
      technologies: ['Azure', 'Xamarin', 'iOS', 'Android', 'Windows Phone'],
      position: 'Founder',
      description: (
        <Typography variant="subtitle1">
          A mobile application designed for tracking multiple lap times during
          motorcross training. Inspired by the need of an ex-motocross trainer
          who trained multiple people at once, this app allowed for easy timing
          of multiple laps and uploading the lap times to the cloud for sharing
          on social media. This project demonstrated my ability to design and
          develop a mobile application, as well as my passion for
          problem-solving in a sport I am interested in.
        </Typography>
      ),
      featuredImage: 'mototime.png',
    },
  ]

  return (
    <Stack sx={wrapperSx} id={HOME_ANCHOR_ID.PERSONAL_PROJECTS}>
      <Typography variant="h1">Personal</Typography>
      <Stack sx={itemsWrapperSx}>
        {PERSONAL_LIST.map((v, i) => {
          const variant = i % 2 === 0 || isMobileOrTablet ? 'left' : 'right'
          return <FeaturedItem key={v.title} variant={variant} {...v} />
        })}
      </Stack>
    </Stack>
  )
}

export default Personal
